export default function gMapDisplay(_container, _zoom){
  let contaier = _container;

  const latlng = new google.maps.LatLng(35.645566, 139.745328);
  const myOptions = {
      zoom: _zoom,
      center: latlng,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl:false,
      mapTypeControl: false,
      zoomControl:true
  };
  console.log(myOptions);

  const latlngIco = new google.maps.LatLng(35.644923, 139.745328);
  const map = new google.maps.Map(_container, myOptions);
  const icon = new google.maps.MarkerImage(
      'img/access/marker.png',
      new google.maps.Size(120,54),
      new google.maps.Point(0,0)
  );

  const markerOptions = {
  position: latlngIco,
  map: map,
  icon: icon,
  title: '三田ベルジュビル'
};
  const marker = new google.maps.Marker(markerOptions);
  const styleOptions = [
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];
  const styledMapOptions = { name: '三田ベルジュビル' }
  const mitaType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
  map.mapTypes.set('bellju', mitaType);
  map.setMapTypeId('bellju');
}
