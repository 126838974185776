export default function intro(){
  const btnCloseMask = document.querySelector('.js-openmovie');
  const btnOpenSeren = document.querySelector('.js-openseren');
  const maskMovie = document.querySelector('.js-maskMovie');
  const heroMovie = document.querySelector('.home-intro');
  const homeHero = document.querySelector('.home-hero');
  const secBlank = document.querySelector('.home-blank');
  const secSeren = document.querySelector('.home-seren');


  const closeMask = ()=>{
    if(!heroMovie.classList.contains('is-active')){
      maskMovie.classList.add('is-idle');
      heroMovie.classList.add('is-active');
    }else{
      homeHero.classList.add('is-idle');
      secBlank.classList.add('is-active');
    }
  }
  const openSeren = ()=>{
    if(!secSeren.classList.contains('is-active')){
      secBlank.classList.remove('is-active');
      secBlank.classList.add('is-idle');
      secSeren.classList.add('is-active');
    }
  }

  const events = ()=>{
    // btnCloseMask.addEventListener('click', closeMask, false);
    // btnOpenSeren.addEventListener('click', openSeren, false);
  };

  events();
}
