import LoopSlider from './modules/loop-slider';
import ToggleNav from './modules/nav-sp-toggle';
import AddHover from './modules/addhover';
import pageTop from './modules/pagetop';
import scrollToggle from './modules/scrollToggle';
import fullHeight from './modules/fullheight';
import gMapDisplay from './modules/map';
import SlideShow from './modules/slideshow';
import movieYoutube from './modules/youtube';
import intro from './modules/intro';
import Glide from '@glidejs/glide';

const run = () => {
  // フルページコンテンツを生成
  let preloadImage = document.querySelectorAll('.js-preload') || [];
  const gnav = document.querySelector('.c-gnav_wrap') || null;
  const gmap = document.getElementById('js-gmap') || null;
  const slider = document.querySelector('.js-slider') || null;
  const slider2 = document.querySelector('.js-slider2') || null;
  const movie = document.querySelector('.section-intro') || null;
  const loop = document.querySelector('.js-loopslider') || null;
  const maskwrapper = document.querySelector('.js-maskMovie') || null;
  const glide = document.querySelector('.glide') || null;

  // if(window.innerWidth > 767){
  //   
  // }
  fullHeight();
  
  ToggleNav('js-gnav', '.site-gnav_item');

  if(maskwrapper){ intro(); }
  if(slider){ new SlideShow(slider); }
  if(slider2){ new SlideShow(slider2); }

  if(gmap){ gMapDisplay(gmap, 17); }
  // if(movie){
  //   movieYoutube();
  // }

  if(document.querySelector('.home-intro')){
    setTimeout(()=>{
      let video = document.querySelector('.home-intro > video');
      const resize = ()=>{
        let aspect = window.innerWidth / window.innerHeight;
        if(aspect < 16 / 9){
          video.style.height = window.innerHeight + 100 + 'px';
          video.style.width = 'auto';
        }else{
          video.style.width = window.innerWidth + 'px';
          video.style.height = 'auto';
        }
      }
      
      resize();
      video.classList.add('active');
      window.addEventListener('resize', resize, false);
    }, 800);
  }


  if(preloadImage){
    imagesLoaded(preloadImage, () => {
      const startPage = () => {
        // setTimeout(()=>{
        //   //------------------------------
        //   // Common
        //   //------------------------------
        //   ToggleNav('js-nav-toggle', 'js-gnav', '.c-gnav__item');
        //   AddHover();
        //   pageTop(2);
        //   scrollToggle();

        //   document.body.classList.remove('loading');
        //   document.body.classList.add('loaded');

        //   if(!gnav.classList.contains('is-view') && !glWrapper){ gnav.classList.add('is-view'); }

        // }, 800);
        
    
        if(loop){ new LoopSlider(); }
        if(glide){
          new Glide('.glide',{
            type:'carousel',
            focusAt: 'center',
            autoplay:5000,
            startAt: 0,
            perView: 5.5,
            animationDuration: 800,
            swipeThreshold:80,
            dragThreshold:120,
            gap:0,
            breakpoints: {
              1920:{
                perView: 4.5
              },
              1600:{
                perView: 3.5
              },
              1280: {
                perView: 2.8
              },
              768: {
                perView: 1.8
              },
              375:{
                perView:1.2
              }
            }
          }).mount();
          // }).mount({ Controls, Breakpoints });
        }
      };
      startPage();
    });
  }
  // images loaded








  };

document.addEventListener('DOMContentLoaded', run, false);
