const throttle = require('throttle-debounce/throttle');

export default class SlideShow{
  constructor(wrapper){
    this.wrapper = wrapper || null;
    this.sliderGroup = this.wrapper.querySelector('.slider-content');
    this.slides = this.sliderGroup.querySelectorAll('.slider_item');
    this.navBtn = this.wrapper.querySelectorAll('.js-slider_nav > .slider_btn');
    this.indi = this.wrapper.querySelector('.js-slider-indi');
    this.indicators = [];

    this.count = this.slides.length;
    this.currentIndex = 0;
    this.interval = 10000;
    this.duration = 500;
    this.timer = null;

    this.init();

  }

  init(){
    this.resize();
    this.addIndicator();
    this.updateNav(this.currentIndex);
    this.event();
  }

  addIndicator(){
    for(let i = 0; i < this.count; i++){
      let elm = document.createElement('div');
      elm.classList.add('slider-indi');
      this.indicators.push(elm);
      this.indi.appendChild(elm);
    }
  }

  resize(){
    for(let i = 0; i < this.count; i++){
      // this.slides[i].querySelector('.inner').style.width = this.wrapper.clientWidth + 'px';
    }
  };

  gotoSlide(index){
    for(let i = 0; i < this.count; i++){
      this.slides[i].classList.remove('is-active');
      if(this.slides[i].classList.contains('is-past')){
        this.slides[i].classList.remove('is-past');
      }
    }

    this.slides[this.currentIndex].classList.add('is-past');
    this.currentIndex = index;
    

    if(this.currentIndex > this.count - 1){
        this.currentIndex = 0;
    }else if(this.currentIndex < 0){
        this.currentIndex = this.count - 1;
    }
    this.updateNav(this.currentIndex);
    this.slides[this.currentIndex].classList.add('is-active');
  }

  updateNav(index){
    for(let i = 0; i < this.count; i++){
      this.indicators[i].classList.remove('is-active');
    }
    this.indicators[index].classList.add('is-active');
  }

  startTimer(){
    this.timer = setInterval(this.gotoSlide.bind(this, (this.currentIndex + 1) % this.count), this.interval);
  }

  stopTimer(){
    clearInterval(this.timer);
  }

  event(){
    // let self = this;
    window.addEventListener('resize', throttle(200, ()=>{
      this.resize();
    }), false);

    for(let i = 0; i < this.navBtn.length; i++){
      this.navBtn[i].addEventListener('click', ()=>{
        this.stopTimer();
        if(this.navBtn[i].classList.contains('is-prev')){
          this.gotoSlide(this.currentIndex - 1);
        }else{
          this.gotoSlide(this.currentIndex + 1);
        }
      },false);
    }
  }


}
