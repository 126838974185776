export default class LoopSlider{
  constructor(){
    this.wrap = document.querySelector('.js-loopslider') || null;
    this.inner = document.querySelector('.js-loop_inner') || null;
    this.innerImage = document.querySelector('.js-loop_inner > img') || null;
    this.init();
    this.resizeElm();
  }
  init(){
    if(!this.wrap) return;
    this.posX = 0;
    this.clone = this.inner.firstElementChild.cloneNode(true);
    this.inner.appendChild(this.clone);
  }
  render(){
    this.posX += this.dx;
    if(this.posX > this.imgWidth){
      this.posX = 0;
    }
    this.innerImage.style.transform = `translate3d(-${this.posX}px, 0, 0)`;
    this.clone.style.transform = `translate3d(-${this.posX}px, 0, 0)`;
    requestAnimationFrame(() => this.render());
  }
  resizeElm(){
    this.width = this.wrap.clientWidth;
    this.height = this.wrap.clientHeight;
    this.imgWidth = this.innerImage.width;
    this.inner.style.width = this.imgWidth * 2 + 10 + 'px';
    this.dx = 0.4;
    requestAnimationFrame(() => this.render());
  }
  events(){
    window.addEventListener('resize', this.resizeElm.bind(this),false);
  }

}
