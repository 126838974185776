const throttle = require('throttle-debounce/throttle');
export default function scrollToggle(elm){

  let btnPagetop = document.getElementById('pagetop') || null;
  let target = window.innerHeight * 0.8;
  let posY;


  window.addEventListener('scroll', throttle(200, ()=>{
    posY = window.pageYOffset;
    if(posY > target){
      btnPagetop.classList.add('active');
    }else{
      btnPagetop.classList.remove('active');
    }
  }), false);

}
